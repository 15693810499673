<template>
  <div id="comments-list">
    <section class="comments">
      <b-row
        align-v="center"
        v-for="(comment, index) in comments"
        :key="index"
        class="mt-2"
      >
        <b-col cols="11">
          <div class="d-flex align-items-center">
            <p class="blue-text user-name">{{ comment.professional.name }}</p>
            <span class="blue-text simple-text ml-2">
              {{ getFormatedTimeStamp(comment.updated_at) }}
            </span>
          </div>

          <p class="simple-text comment mt-1">{{ comment.comment }}</p>
        </b-col>
        <b-col cols="1" v-if="canEdit(comment)">
          <span class="edit ml-auto">
            <Edit class="icon" @click="$emit('edit', index)" />
          </span>
        </b-col>
        <div class="divider mt-3" />
      </b-row>
    </section>

    <div v-if="isLoading">
      <b-skeleton width="100%" v-for="i in 3" :key="i" class="mb-2" />
    </div>
  </div>
</template>

<script>
import { getCurrentUser } from '@/utils/localStorageManager'
import { getFormatedTimeStamp } from '@/utils/dateHelper'

export default {
  name: 'CommentList',
  props: {
    surgeryInformationId: String,
    commentsProps: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Edit: () => import('@/assets/icons/edit.svg')
  },
  data: () => ({
    user: getCurrentUser(),
    comments: [],
    isLoading: false
  }),
  async created() {
    await this.getComments()
  },
  methods: {
    getFormatedTimeStamp,
    canEdit(comment) {
      return (
        this.user.id === comment.professional.id &&
        this.moment().diff(comment.created_at, 'hours') < 24
      )
    },
    async getComments() {
      if (!this.surgeryInformationId) return
      this.isLoading = true
      try {
        const { data } = await this.api.surgeryInformationComments(
          this.surgeryInformationId
        )
        this.comments = data

        this.$emit('update-comments', this.comments)
      } catch (error) {
        this.$toast.error('Erro ao carregar comentários')
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    commentsProps: {
      handler(value) {
        if (!value) return

        this.comments = [...value]
      },
      deep: true,
      immediate: true
    },
    surgeryInformationId: {
      async handler(value) {
        if (!value || this.comments.length) return
        await this.getComments()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#comments-list {
  .comments {
    .blue-text {
      color: var(--dark-blue);
    }

    .simple-text {
      font-size: min(1.2vw, 16px);
      font-weight: 400;
    }
    .user-name {
      font-size: min(2.6vw, 18px);
      font-weight: 600;
    }

    .comment {
      color: var(--type-active);
    }

    .edit {
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: var(--neutral-200);
      margin-bottom: 20px;
    }
    .row:last-child {
      .divider {
        display: none;
      }
    }
  }
}
</style>
